<template>
<el-form
    label-position="right"
    label-width="100px"
    class="add-or-edit-form"
    ref="form"
    :model="form"
    :rules="rules"
>
  <el-form-item
      prop="type"
      label="商品类型"
  >
    <el-radio-group
        v-model="form.type"
        :disabled="isEdit"
    >
      <el-radio
          :label="1"
      >实物</el-radio>
      <el-radio
          :label="2"
      >赠金</el-radio>
    </el-radio-group>
  </el-form-item>
  <el-form-item
      label="商品名称"
      prop="productName"
  >
    <el-input
        v-model="form.productName"
        placeholder="请输入"
        maxlength="30"
        show-word-limit
    ></el-input>
  </el-form-item>
  <el-row :gutter="30">
    <el-col
        v-if="form.type == 2"
        :span="8">
      <el-form-item
          label="赠金数量"
          prop="bonusAmount"
      >
        <el-input
            v-model="form.bonusAmount"
            placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :span="colSpan">
      <el-form-item
          label="原价"
          prop="originalPrice"
      >
        <el-input
            v-model="form.originalPrice"
            placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :span="colSpan">
      <el-form-item
          label="优惠价"
          prop="deleteCredits"
      >
        <el-input
            v-model="form.preferentialPrice"
            placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-col>
  </el-row>
  <el-form-item
    label="虚拟销量"
    prop="virtualSales"
  >
<!--    @change="handleChange"-->
    <el-input-number
        v-model="form.virtualSales"
        :min="0"
        label="描述文字"
    ></el-input-number>
<!--:max="10"-->
  </el-form-item>
  <el-form-item
    label="商品简介"
    prop="intro"
  >
    <el-input
        type="textarea"
        v-model="form.intro"
        placeholder="请输入"
        show-word-limit
        maxlength="100"
        rows="5"
    />
  </el-form-item>
  <el-form-item
      label="封面图"
      prop="coverImage"
  >
    <div class="upload-box cover">
      <el-upload
          class="avatar-uploader"
          action="''"
          :accept="getLimitUploadImageFormat()"
          :show-file-list="false"
          :http-request="handleCoverImageSuccess"

          :before-upload="beforeAvatarUpload">
<!--        :on-success="handleCoverImageSuccess"-->
        <img
            v-if="form.coverImage"
            :src="form.coverImage"
            class="avatar middle"
        >
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <uploadImgTips></uploadImgTips>
    </div>
  </el-form-item>
  <el-form-item
      label="轮播图"
      prop="carouselImages"
  >
    <el-upload
        class="avatar-uploader"
        action="''"
        :accept="getLimitUploadImageFormat()"
        list-type="picture-card"
        :show-file-list="true"
        :file-list="form.carouselImages"
        :limit="5"
        :http-request="handleCarouselImageSuccess"
        :on-remove="handleRemoveCarouselImage"
        :before-remove="beforeRemoveCarouselImage"
        :before-upload="beforeAvatarUpload"
    >
<!--      :on-success="handleCarouselImageSuccess"-->
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <uploadImgTips></uploadImgTips>
  </el-form-item>
  <el-form-item
    label="详情"
    prop="content"
  >
<!--    富文本上传图片不成功,todo  需要另外抽时间解决 解决了avatar-uploader quillEditor内部的document.querySelector(".avatar-uploader-quilleditor input") 类冲突了-->
    <quill-editor
        @catchData="catchData"
        :defaultValues="form.content"
    ></quill-editor>
  </el-form-item>
  <el-form-item
      label="标签"
      prop="tag"
  >
    <el-select
        placeholder="请选择"
        v-model="form.tag"
        clearable
    >
      <el-option
          v-for="(item,index) in tagList"
          :key="index"
          :label="item.tagName"
          :value="item.id"
      ></el-option>
    </el-select>
  </el-form-item>
  <el-form-item
      prop="stick"
      label="是否置顶"
  >
    <el-radio-group
        v-model="form.stick"
    >
      <el-radio
          :label="1"
      >是</el-radio>
      <el-radio
          :label="0"
      >否</el-radio>
    </el-radio-group>
  </el-form-item>
  <el-form-item
      prop="status"
      label="状态"
  >
    <el-radio-group
        v-model="form.status"
    >
      <el-radio
          :label="1"
      >上架</el-radio>
      <el-radio
          :label="0"
      >下架</el-radio>
    </el-radio-group>
  </el-form-item>
  <el-form-item>

    <div class="btn-outer">
      <el-button
          size="small"
          type="primary"
          @click="sureSubmit"
      >确认</el-button>
    </div>
  </el-form-item>
</el-form>
</template>

<script>
import { uploadFile } from '@/api/data'
import { getBase64,base64toFile } from '@/utils/index'
import { getLimitUploadImageFormat,limitImageSizeFormat } from '@/utils/index'
import { mapGetters } from 'vuex'
import quillEditor from '@/components/tools/quillEditor'
import { addProduct,editProduct,queryTagList } from '@/api/intergratingManage/productManage'
export default {
  name: 'addOrEditForm',
  components: {
    quillEditor
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    formData:{
      type: Object,
      default(){
        return {

        }
      }
    }
  },
  data() {
    return {
      form:{
        type: 1,
        productName: '',
        bonusAmount: '', // 赠金数量
        originalPrice: '',
        preferentialPrice: '',
        virtualSales: '',
        intro: '',
        coverImage: '',
        carouselImages: [],
        content: '',
        tag: '',
        userDefined: 1,
        stick: 0,
        status: 1,
      },
      rules:{
        type:[
          { required: true, message: '请选择商品类型',trigger:'change' }
        ],
        productName:[
          { required: true, message: '请输入商品名称',trigger:'blur' }
        ],
        bonusAmount:[
          { required: true, message: '请输入赠金数量',trigger:'blur' }
        ],
        originalPrice:[
          { required: true, message: '请输入兑换积分',trigger:'blur' }
        ],
        preferentialPrice:[
          { required: true, message: '请输入划线积分',trigger:'blur' }
        ],
        virtualSales:[
          { required: true, message: '请输入划线积分',trigger:'blur' }
        ],
        intro:[
          { required: true, message: '请输入简介',trigger:'blur' }
        ],
        coverImage:[
          { required: true, message: '上传封面图',trigger:'blur' }
        ],
        carouselImages:[
          { required: true, message: '上传封面图',trigger:'blur' }
        ],
        tag:[
          // { required: true, message: '请选择标签',trigger:'blur' }
        ],
        userDefined:[
          { required: true, message: '请输入',trigger:'blur' }
        ],
        stick:[
          { required: true, message: '请选择是否置顶',trigger:'change' }
        ],
        status:[
          { required: true, message: '请选择',trigger:'change' }
        ],
        content: ''
      },
      tagList: [
        { label: '无',value: 1 },
        { label: '推荐',value: 2 },
        { label: '新品',value: 3 },
      ]
    }
  },
  created(){
    this.getTagList();
  },
  watch:{
    formData:{
      handler(newVal){
        if(newVal){
          // 给form赋值
          this.form.type = newVal.type;
          this.form.productName = newVal.productName;
          this.form.originalPrice = newVal.originalPrice;
          this.form.preferentialPrice = newVal.preferentialPrice;
          this.form.virtualSales = newVal.fakeSales;
          this.form.intro = newVal.intro;
          this.form.coverImage = newVal.frontImg;
          this.form.content = newVal.detail;
          this.form.stick = newVal.isTop;
          this.form.status = newVal.status;
          if(newVal.tagId){
            this.form.tag = newVal.tagId;
          }
          let copySildeImgs = [];
          for (let i = 0; i < newVal.slideImgs.length; i++) {
            copySildeImgs.push({
              name: this.$subsctringFileName(newVal.slideImgs[i]),
              fileName: this.$subsctringFileName(newVal.slideImgs[i]),
              url: newVal.slideImgs[i],
            })
          }
          this.form.carouselImages = copySildeImgs;
          if(newVal.type == 2){
            this.form.bonusAmount = newVal.bonusAmount
          }
        }
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters(['isRefresh']),
    colSpan(){
      return 8;
    }
  },
  methods: {
    getLimitUploadImageFormat,
    addProduct,
    editProduct,
    getTagList(){
      queryTagList().then((res) => {
        this.tagList = res;
      })
    },
    beforeAvatarUpload(file) {
      return limitImageSizeFormat(file,this);
    },
    handleCoverImageSuccess(item){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.form.coverImage = res.data.content[0].url;
        });
      });
    },
    //轮播图
    handleCarouselImageSuccess(item,fileList){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          if(res.data.content[0].url && !this.form.carouselImages.includes(res.data.content[0].url)){
            this.form.carouselImages.push(res.data.content[0].url);
          }
        });
      });
      // 用一个数组存起来,删除的时候也要对应上,替换的时候也要对应上.
    },
    beforeRemoveCarouselImage(file,fileList){
      console.log(file);
      console.log(fileList);
      let indexFile = fileList.indexOf(file);
      if(indexFile > -1){
        this.form.carouselImages.splice(indexFile, 1);
      }
      return true;
    },
    handleRemoveCarouselImage(file, fileList) {


    },
    catchData(value) {
      //在这里接受子组件传过来的参数，赋值给data里的参数
      this.form.content = value
    },
    sureSubmit(){
      this.$refs.form.validate((valid) => {
        if(valid){
          let params = {
            type: this.form.type,
            productName: this.form.productName,
            originalPrice: this.form.originalPrice,
            preferentialPrice	: this.form.preferentialPrice,
            fakeSales: this.form.virtualSales,
            intro: this.form.intro,
            frontImg: this.form.coverImage,
            detail: this.form.content,
            isTop: this.form.stick,
            status: this.form.status,
          }
          if(this.form.type == 2){
            params.bonusAmount = this.form.bonusAmount;
          }
          if(this.form.tag){
            params.tagId = this.form.tag;
          }
          let copyCarouselImages = JSON.parse(JSON.stringify(this.form.carouselImages));
          for (let i = 0; i < copyCarouselImages.length; i++) {
            copyCarouselImages[i] = copyCarouselImages[i].url;
          }
          params.slideImgs = copyCarouselImages;
          let methodsName = '';
          if(!this.isEdit){
            methodsName = 'addProduct';
          }else {
            params.id = this.formData.id;
            methodsName = 'editProduct';
          }
          this[methodsName](params).then((res) => {
            this.$notify({
              title: '操作成功',
              type: 'success',
              duration: 2500
            });
            // 可能需求更新商品管理列表
            this.$store.commit('TOGGLE_ISREFRESH',!this.isRefresh);
            this.$store.state.tagsView.visitedViews.splice(this.$store.state.tagsView.visitedViews.findIndex(item => item.name === this.$route.name), 1);
            this.$router.go(-1);
          })
        }else {
          console.log('no-pass');
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.add-or-edit-form {
  width: 800px;
  .cover {
    .avatar-uploader {
      width: 148px;
      height: 148px;
      border: 1px dotted #c0c0c0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /deep/ .el-upload {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #c0c0c0;
      }
      /deep/ i {
        font-size: 28px;
      }
      .avatar {
        width: 100%;
        height: 100%;
      }
    }
  }
  .upload-img-tip {
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 20px;
  }
  .btn-outer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
