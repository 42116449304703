import request from '@/utils/request'

// 需要更换url todo
export function addProduct(data) {
  return request({
    url: 'crm/tShopProduct',
    method: 'post',
    data
  })
}

export function editProduct(data) {
  return request({
    url: 'crm/tShopProduct',
    method: 'put',
    data
  })
}
//
export function queryTagList(data) {
  return request({
    url: 'crm/validProductTag',
    method: 'get',
    params: data
  })
}

export function getProductInfo(data) {
  return request({
    url: 'crm/tShopProduct',
    method: 'get',
    params: data
  })
}

// 审核积分兑换
export function auditExchange(data) {
  return request({
    url: 'crm/rewardRedeemRecord/audit',
    method: 'put',
    data
  })
}
