<template>
<div class="add-porduct" ref="editproduct">
  <add-or-edit-form
      :form-data="formData"
      :is-edit="true"
  ></add-or-edit-form>
</div>
</template>

<script>

import addOrEditForm from '@/components/intergratingManage/productManage/addOrEditForm'
import { getProductInfo } from '@/api/intergratingManage/productManage'
export default {
  name: 'addProduct',
  components:{
    addOrEditForm
  },
  data() {
    return {
      formData: {},
    }
  },
  mounted(){
    this.getPageProductInfo();
  },
  methods: {
    getPageProductInfo() {
      getProductInfo({id: this.$route.params.id}).then((res) => {
        this.formData = res.content[0];
      })
    }
  }
}
</script>

<style lang="less" scoped>
.add-porduct {
  padding-left: 30px;
  padding-top: 30px;
}
</style>
