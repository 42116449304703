<template>
<div class="add-porduct">
  <add-or-edit-form></add-or-edit-form>
</div>
</template>

<script>
import addOrEditForm from '@/components/intergratingManage/productManage/addOrEditForm'
export default {
  name: 'addProduct',
  components:{
    addOrEditForm
  }
}
</script>

<style lang="less" scoped>
.add-porduct {
  padding-left: 30px;
  padding-top: 30px;
}
</style>
